import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import BusinessFeatures from './components/BusinessFeatures';
import Benefits from './components/Benefits';
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import IndustrySolutions from './components/IndustrySolutions';
import ProgressTracker from './components/ProgressTracker';
import InteractiveSection from './components/InteractiveSection';
import Header2 from './components/Header2';


function App() {
  return (
    <div style={{overflowX: "hidden"}}>
    {/* <Header /> */}
    <Header/>
    <Hero  />
    <ProgressTracker  />
    <Features />
    <IndustrySolutions id="industry-solutions" />
    {/* <BusinessFeatures id="business-features" /> */}
    <Benefits  />
    <Pricing id="pricing" />
    <Footer id="footer" />
  </div>
  );
}

export default App;

