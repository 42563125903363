import React, { useEffect, useState } from 'react';
import './Header.css'; // Importuj plik CSS

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    closeMenu(); // Zamknij menu po kliknięciu w link
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="logo" onClick={() => scrollToSection('#hero')}>
          xTemplater
        </div>
        <nav className="nav">
          {['How It Works', 'Features', 'Solutions', 'Benefits', 'Pricing'].map((item, index) => (
            <span
              key={index}
              className="link"
              onClick={() => scrollToSection(`#${item.toLowerCase().replace(/ /g, '-')}`)}
            >
              {item}
            </span>
          ))}
          <a
            href="https://app.xtemplater.com"
            className="cta"
            target="_blank"
            rel="noopener noreferrer"
          >
            Log In
          </a>
        </nav>
        <button
          className="menu-button"
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
        >
          <div className={`hamburger ${isMenuOpen ? 'open' : ''}`}>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </div>
        </button>
        <nav className={`mobile-nav ${isMenuOpen ? 'open' : ''}`}>
          {['How It Works', 'Features', 'Solutions', 'Benefits', 'Pricing'].map((item, index) => (
            <span
              key={index}
              className="link"
              onClick={() => scrollToSection(`#${item.toLowerCase().replace(/ /g, '-')}`)}
            >
              {item}
            </span>
          ))}
          <a
            href="https://app.xtemplater.com"
            className="cta"
            target="_blank"
            rel="noopener noreferrer"
          >
            Log In
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;