import React, { useEffect, useState, useRef } from "react";
import "./Pricing.css";
import PricingCard from "./PricingCard";

function Pricing() {
  const [isVisible, setIsVisible] = useState(false);
  const [billingCycle, setBillingCycle] = useState("monthly"); // State for billing cycle
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      { threshold: 0.3 } // Trigger when 30% of the element is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Toggle billing cycle state
  const toggleBillingCycle = () => {
    setBillingCycle((prev) => (prev === "monthly" ? "yearly" : "monthly"));
  };

  return (
    <section
      ref={sectionRef}
      className={`pricing ${isVisible ? "pricing--visible" : ""}`}
      id="pricing"
    >
      <div className="pricing__container">
        <h2
          className={`pricing__title ${
            isVisible ? "pricing__title--visible" : ""
          }`}
        >
          Pricing
        </h2>
        <p
          className={`pricing__description ${
            isVisible ? "pricing__description--visible" : ""
          }`}
        >
          One plan for all your needs.
        </p>

        {/* Billing Cycle Toggle */}
        <div className="pricing__toggle-wrapper">
          <span className={billingCycle === "monthly" ? "active" : ""}>
            Monthly
          </span>
          <label className="pricing__toggle">
            <input
              type="checkbox"
              checked={billingCycle === "yearly"}
              onChange={toggleBillingCycle}
            />
            <span className="pricing__slider"></span>
          </label>
          <span className={billingCycle === "yearly" ? "active" : ""}>
            Yearly
          </span>
        </div>

        {/* Pricing Card */}
        <PricingCard billingCycle={billingCycle} />
      </div>
    </section>
  );
}

export default Pricing;
