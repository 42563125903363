import React from "react";
import "./PricingCard.css";

function PricingCard({ billingCycle }) {
  return (
    <div className="pricing-card">
      <h2 className="pricing-card__title">Pro Plan</h2>
      <div className="pricing-card__price">
        {billingCycle === "monthly" ? "$22" : "$18"}
        <span className="pricing-card__per-user"> / user</span>
      </div>
      <div className="pricing-card__per-user">
        {billingCycle === "yearly" && "Save 15% with annual billing"}
        {billingCycle === "monthly" && "Billed monthly"}
      </div>
      <ul className="pricing-card__benefits-list">
        <li className="pricing-card__list-item">Unlimited templates</li>
        <li className="pricing-card__list-item">AI-powered document creation</li>
        <li className="pricing-card__list-item">Priority email support</li>
        <li className="pricing-card__list-item">Customizable templates</li>
      </ul>
      <div className="pricing-card__special-offer">
        Need licenses for your organization or institution? Get in touch for
        bulk discounts and custom offers!{" "}
        <a href="mailto:sales@xtemplater.com" className="pricing-card__contact">
          Contact Us
        </a>
      </div>
      <a
        href="https://app.xtemplater.com"
        target="_blank"
        rel="noopener noreferrer"
        className="pricing-card__button"
      >
        Join Waitlist
      </a>
    </div>
  );
}

export default PricingCard;
