import React, { useEffect, useState } from "react";
import "./Benefits.css";

const benefits = [
  "Rapid Creation",
  "Customizable Templates",
  "Seamless Collaboration",
  "Seamless Integration",
];

const stats = [
  { label: "Documents Generated", target: 100 },
  { label: "Supported Languages", target: 25 },
  { label: "Community Templates", target: 10 },
];

function Benefits() {
  const [isVisible, setIsVisible] = useState(false);
  const [animatedNumbers, setAnimatedNumbers] = useState({
    documents: 0,
    languages: 0,
    templates: 0,
  });

  useEffect(() => {
    const section = document.getElementById("benefits");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(section); // Stop observing after becoming visible
        }
      },
      { threshold: 0.2 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      stats.forEach((stat) => {
        const duration = 2000; // Animation duration in milliseconds
        const increment = 20; // Interval for updates in milliseconds
        const steps = duration / increment;
        const stepValue = stat.target / steps;

        let current = 0;

        const interval = setInterval(() => {
          current += stepValue;
          if (current >= stat.target) {
            current = stat.target;
            clearInterval(interval);
          }

          setAnimatedNumbers((prev) => ({
            ...prev,
            [stat.label.split(" ")[0].toLowerCase()]: Math.floor(current),
          }));
        }, increment);
      });
    }
  }, [isVisible]);

  return (
    <section
      id="benefits"
      className={`benefits ${isVisible ? "benefits--visible" : ""}`}
    >
      <div className="benefits__container">
        <h2 className={`benefits__title ${isVisible ? "benefits__title--visible" : ""}`}>
          How xTemplater Benefits Your Business
        </h2>
        <div className={`benefits__content-wrapper ${isVisible ? "benefits__content-wrapper--visible" : ""}`}>
          <ul className="benefits__list">
            {benefits.map((benefit, index) => (
              <li
                key={index}
                className={`benefits__list-item ${isVisible ? "benefits__list-item--visible" : ""}`}
                style={{ transitionDelay: `${index * 0.2}s` }} // Staggered animation
              >
                <div className="benefits__list-item-icon">{index + 1}</div>
                <div className="benefits__list-item-text">{benefit}</div>
              </li>
            ))}
          </ul>
          <div className={`benefits__image-wrapper ${isVisible ? "benefits__image-wrapper--visible" : ""}`}>
            <img
              src="/images/business.jpg"
              alt="Business Benefits"
              className="benefits__image"
            />
          </div>
        </div>

        <div className={`benefits__stats-section ${isVisible ? "benefits__stats-section--visible" : ""}`}>
          {stats.map((stat, index) => (
            <div key={index} className="benefits__stat-item">
              <div className="benefits__stat-number">
                {animatedNumbers[stat.label.split(" ")[0].toLowerCase()] || 0}+
              </div>
              <div className="benefits__stat-label">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Benefits;
