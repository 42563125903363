import React, { useEffect, useState } from "react";
import "./Hero.css"; // Importuj plik CSS
import SvgIcon from "./SvgIcon";

const icons = [
  { type: "figma", active: true },
  { type: "powerpoint", active: true },
  { type: "word", active: true },
  { type: "outlook", active: true },
  { type: "adobe", active: true },
];

function Hero() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="hero" id="hero">
      <div className="content">
        <h1 className={`title ${isVisible ? "visible" : ""}`}>
          Transform Documents into Dynamic, Multilingual Forms
        </h1>
        <p className={`description ${isVisible ? "visible" : ""}`}>
          Create a multilingual form perfectly suited to your document. Add your own
          labels, hints, and validation rules or let AI handle the entire setup automatically.
        </p>

        <div className={`button-container ${isVisible ? "visible" : ""}`}>
          <a
            href="https://app.xtemplater.com"
            target="_blank"
            rel="noopener noreferrer"
            className="cta"
          >
            Join Waitlist
          </a>
          <a
            href="https://app.xtemplater.com"
            target="_blank"
            rel="noopener noreferrer"
            className="secondary-cta"
          >
            Watch Video
          </a>
        </div>
        <h3 className={`subtitle ${isVisible ? "visible" : ""}`}>
          One Subscription, Multiple Applications
        </h3>
        <div className={`icons-container ${isVisible ? "visible" : ""}`}>
          {icons.map((icon) => (
            <div key={icon.type}>
              <SvgIcon type={icon.type} active={icon.active} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Hero;
