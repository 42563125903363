import React, { useState, useEffect, useRef } from "react";
import "./progressTracker.css";

const steps = [
  {
    id: 1,
    title: "Template Your Document",
    description:
      "Use AI or our applications (also available as plugins) to create document templates effortlessly.",
    video: "/videos/LP1.mp4", // Plik w public/videos
  },
  {
    id: 2,
    title: "Read and Interpret",
    description:
      "Open the document in any app which supports Interpreter to create a form or chatbot.",
    video: "/videos/LP2.mp4", // Plik w public/videos
  },
  {
    id: 3,
    title: "Generate in Multiple Formats",
    description:
      "Generate the document in various formats, ensuring compatibility and versatility.",
    video: "/videos/LP3.mp4", // Plik w public/videos
  },
  {
    id: 4,
    title: "Automate Document Classification for chatbots",
    description:
      "Share templates with the community or your clients for increased collaboration.",
    video: "/videos/LP4.mp4", // Plik w public/videos
  },
];

function ProgressTracker() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const timerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const section = document.getElementById("progress-tracker");
    const isMobileView = window.innerWidth <= 768; // Wykrywaj widok mobilny
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: isMobileView ? 0.1 : 0.3 } // Mniejszy próg dla mobilnych
    );
  
    if (section) observer.observe(section);
  
    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  useEffect(() => {
    // Reset progress and start interval for the progress bar
    setProgress(0);
    clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timerRef.current);
          setActiveStep((prevStep) => (prevStep === steps.length ? 1 : prevStep + 1));
          return 0;
        }
        return prev + 0.5525; // Przyrost na krok
      });
    }, 50); // Interwał w milisekundach

    
    

    return () => clearInterval(timerRef.current);
  }, [activeStep]);


  const handleStepClick = (id) => {
    if (!isMobile) {
      setActiveStep(id);
      setProgress(0);
    }
  };

  useEffect(() => {
    // Detect screen width for mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) return; // Skip progress bar logic for mobile

    setProgress(0);
    clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timerRef.current);
          setActiveStep((prevStep) => (prevStep === steps.length ? 1 : prevStep + 1));
          return 0;
        }
        return prev + 0.5525;
      });
    }, 50);

    return () => clearInterval(timerRef.current);
  }, [activeStep, isMobile]);




  return (
    <section
      className={`progress-tracker ${isVisible ? "visible" : ""}`}
      id="progress-tracker"
    >
      <h2 className="title">How It Works</h2>
      {isMobile ? (
        <div className="mobile-container">
          {steps.map((step) => (
            <div key={step.id} className="step">
              <div className="step-title">{step.title}</div>
              <div className="step-description">{step.description}</div>
              <div className="video-container">
                <video
                  src={step.video}
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="video"
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="container">
          <div className="steps-container">
            {steps.map((step) => (
              <div
                key={step.id}
                className={`step ${activeStep === step.id ? "active" : ""}`}
                onClick={() => handleStepClick(step.id)}
              >
                <div className="step-title">{step.title}</div>
                {activeStep === step.id && (
                  <>
                    <div className="step-description">{step.description}</div>
                    <div className="progress-bar">
                      <div
                        className="progress-fill"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="video-container">
            {isVisible ? (
              <video
                src={steps.find((step) => step.id === activeStep).video}
                autoPlay
                muted
                loop
                playsInline
                className="video"
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default ProgressTracker;
