import React from 'react';

const SvgIcon = ({ type, active }) => {
  const styles = {
    icon: {
      width: '32px',
      height: '32px',
      opacity: active ? 1 : 0.5, // Reduce opacity for inactive state
    },
  };

  switch (type) {
    case 'word':
      return (
        <svg style={styles.icon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="2" width="24" height="28" rx="2" fill="url(#paint0_linear_106_10)"/>
        <path d="M8 23H32V28C32 29.1046 31.1046 30 30 30H10C8.89543 30 8 29.1046 8 28V23Z" fill="url(#paint1_linear_106_10)"/>
        <rect x="8" y="16" width="24" height="7" fill="url(#paint2_linear_106_10)"/>
        <rect x="8" y="9" width="24" height="7" fill="url(#paint3_linear_106_10)"/>
        <path d="M8 12C8 10.3431 9.34315 9 11 9H17C18.6569 9 20 10.3431 20 12V24C20 25.6569 18.6569 27 17 27H8V12Z" fill="black" fill-opacity="0.3"/>
        <rect y="7" width="18" height="18" rx="2" fill="url(#paint4_linear_106_10)"/>
        <path d="M15 11.0142H13.0523L11.5229 17.539L9.84967 11H8.20261L6.51634 17.539L5 11.0142H3L5.60131 21H7.3268L9 14.6879L10.6732 21H12.3987L15 11.0142Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_106_10" x1="8" y1="6.66667" x2="32" y2="6.66667" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2B78B1"/>
        <stop offset="1" stop-color="#338ACD"/>
        </linearGradient>
        <linearGradient id="paint1_linear_106_10" x1="8" y1="27.375" x2="32" y2="27.375" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1B366F"/>
        <stop offset="1" stop-color="#2657B0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_106_10" x1="18.5" y1="20" x2="32" y2="20" gradientUnits="userSpaceOnUse">
        <stop stop-color="#20478B"/>
        <stop offset="1" stop-color="#2D6FD1"/>
        </linearGradient>
        <linearGradient id="paint3_linear_106_10" x1="18.5" y1="13" x2="32" y2="13" gradientUnits="userSpaceOnUse">
        <stop stop-color="#215295"/>
        <stop offset="1" stop-color="#2E84D3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_106_10" x1="3.31137e-08" y1="17" x2="19" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#223E74"/>
        <stop offset="1" stop-color="#215091"/>
        </linearGradient>
        </defs>
        </svg>
      );
    case 'figma':
      return (
        <svg style={styles.icon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16C16 13.7909 17.7909 12 20 12C22.2091 12 24 13.7909 24 16C24 18.2091 22.2091 20 20 20C17.7909 20 16 18.2091 16 16Z" fill="#1ABCFE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 24C8 21.7909 9.79086 20 12 20H16V24C16 26.2091 14.2091 28 12 28C9.79086 28 8 26.2091 8 24Z" fill="#0ACF83"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4V12H20C22.2091 12 24 10.2091 24 8C24 5.79086 22.2091 4 20 4H16Z" fill="#FF7262"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8C8 10.2091 9.79086 12 12 12H16V4H12C9.79086 4 8 5.79086 8 8Z" fill="#F24E1E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C8 18.2091 9.79086 20 12 20H16V12H12C9.79086 12 8 13.7909 8 16Z" fill="#A259FF"/>
        </svg>
      );
    case 'adobe':
      return (
        <svg style={styles.icon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 12.1333C2 8.58633 2 6.81283 2.69029 5.45806C3.29749 4.26637 4.26637 3.29749 5.45806 2.69029C6.81283 2 8.58633 2 12.1333 2H19.8667C23.4137 2 25.1872 2 26.5419 2.69029C27.7336 3.29749 28.7025 4.26637 29.3097 5.45806C30 6.81283 30 8.58633 30 12.1333V19.8667C30 23.4137 30 25.1872 29.3097 26.5419C28.7025 27.7336 27.7336 28.7025 26.5419 29.3097C25.1872 30 23.4137 30 19.8667 30H12.1333C8.58633 30 6.81283 30 5.45806 29.3097C4.26637 28.7025 3.29749 27.7336 2.69029 26.5419C2 25.1872 2 23.4137 2 19.8667V12.1333Z" fill="#B30B00"/>
        <path d="M24.0401 17.8976C22.7327 16.464 19.1701 17.0912 18.3094 17.1808C17.0891 15.9264 16.2284 14.504 15.8798 13.9664C16.3156 12.6224 16.6642 11.1104 16.6642 9.6768C16.6642 8.3328 16.1413 7 14.7576 7C14.2347 7 13.7989 7.2688 13.5374 7.7168C12.9273 8.792 13.1887 10.9312 14.1475 13.16C13.6245 14.7728 12.753 17.1808 11.7179 19.0512C10.3234 19.5888 7.28369 21.0112 7.02221 22.624C6.93505 23.072 7.10937 23.6096 7.45801 23.8784C7.80665 24.2368 8.24244 24.3264 8.67824 24.3264C10.4977 24.3264 12.328 21.7392 13.6354 19.4096C14.6814 19.0512 16.3265 18.5136 17.9825 18.2448C19.8891 20.0368 21.6323 20.2944 22.5039 20.2944C23.7242 20.2944 24.16 19.7568 24.3234 19.3088C24.5522 18.8832 24.3887 18.256 24.0401 17.8976ZM22.8199 18.7936C22.7327 19.152 22.2969 19.5104 21.5125 19.3312C20.5537 19.0624 19.693 18.6144 18.9958 17.9872C19.6059 17.8976 21.0767 17.7184 22.1226 17.8976C22.4712 17.9872 22.907 18.256 22.8199 18.7936ZM14.3872 8.0752C14.4744 7.896 14.6487 7.8064 14.823 7.8064C15.2588 7.8064 15.3459 8.344 15.3459 8.792C15.2588 9.8672 15.0845 11.0208 14.7358 12.0064C14.0386 10.0464 14.1257 8.6128 14.3872 8.0752ZM14.3 18.1664C14.7358 17.36 15.2588 15.848 15.4331 15.3104C15.8689 16.1168 16.6533 17.0128 17.002 17.4496C17.0891 17.3712 15.5203 17.7184 14.3 18.1664ZM11.3475 20.2272C10.1382 22.1872 9.00509 23.4416 8.30781 23.4416C8.22065 23.4416 8.04634 23.4416 7.95918 23.352C7.87202 23.1728 7.78486 22.9936 7.87202 22.8144C7.95918 22.0976 9.35373 21.112 11.3475 20.2272Z" fill="white"/>
        </svg>
      );

    case 'powerpoint': 
    return (
        <svg
        width="32"
        style={styles.icon}
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17" cy="16" r="14" fill="url(#paint0_linear_1_13542)" />
        <mask
          id="mask0_1_13542"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="2"
          width="28"
          height="28"
        >
          <circle cx="17" cy="16" r="14" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_1_13542)">
          <rect
            x="18"
            width="17"
            height="17"
            fill="url(#paint1_linear_1_13542)"
          />
          <path
            d="M6 12C6 10.3431 7.34315 9 9 9H17C18.6569 9 20 10.3431 20 12V24C20 25.6569 18.6569 27 17 27H6V12Z"
            fill="black"
            fillOpacity="0.3"
          />
          <rect x="1" width="17" height="17" fill="#EB6C4D" />
        </g>
        <rect
          y="7"
          width="18"
          height="18"
          rx="2"
          fill="url(#paint2_linear_1_13542)"
        />
        <path
          d="M13 14.4571C13 12.3 11.5799 11 9.32787 11H6V21H8.06557V17.9571H9.2418C11.3504 17.9571 13 16.7571 13 14.4571ZM10.9057 14.5C10.9057 15.4714 10.2889 16.1 9.28484 16.1H8.06557V12.8714H9.27049C10.2746 12.8714 10.9057 13.4 10.9057 14.5Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_13542"
            x1="3"
            y1="17.931"
            x2="31"
            y2="17.931"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A73A24" />
            <stop offset="1" stopColor="#F75936" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_13542"
            x1="31.5"
            y1="10"
            x2="18"
            y2="10"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDB8A3" />
            <stop offset="1" stopColor="#F1876D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1_13542"
            x1="0"
            y1="17.2414"
            x2="18"
            y2="17.2414"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A73A24" />
            <stop offset="1" stopColor="#F75936" />
          </linearGradient>
        </defs>
      </svg>

    );
    case "outlook":
    return (
        <svg
        style={styles.icon}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="10" y="2" width="20" height="28" rx="2" fill="#1066B5" />
        <rect x="10" y="2" width="20" height="28" rx="2" fill="url(#paint0_linear_1_13543)" />
        <rect x="10" y="5" width="10" height="10" fill="#32A9E7" />
        <rect x="10" y="15" width="10" height="10" fill="#167EB4" />
        <rect x="20" y="15" width="10" height="10" fill="#32A9E7" />
        <rect x="20" y="5" width="10" height="10" fill="#58D9FD" />
        <mask id="mask0_1_13543" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="8" y="14" width="24" height="16">
          <path d="M8 14H30C31.1046 14 32 14.8954 32 16V28C32 29.1046 31.1046 30 30 30H10C8.89543 30 8 29.1046 8 28V14Z" fill="url(#paint1_linear_1_13543)" />
        </mask>
        <g mask="url(#mask0_1_13543)">
          <path d="M32 14V18H30V14H32Z" fill="#135298" />
          <path d="M32 30V16L7 30H32Z" fill="url(#paint2_linear_1_13543)" />
          <path d="M8 30V16L33 30H8Z" fill="url(#paint3_linear_1_13543)" />
        </g>
        <path d="M8 12C8 10.3431 9.34315 9 11 9H17C18.6569 9 20 10.3431 20 12V24C20 25.6569 18.6569 27 17 27H8V12Z" fill="black" fillOpacity="0.3" />
        <rect y="7" width="18" height="18" rx="2" fill="url(#paint4_linear_1_13543)" />
        <path d="M14 16.0693V15.903C14 13.0222 11.9272 11 9.01582 11C6.08861 11 4 13.036 4 15.9307V16.097C4 18.9778 6.07278 21 9 21C11.9114 21 14 18.964 14 16.0693ZM11.6424 16.097C11.6424 18.0083 10.5665 19.1579 9.01582 19.1579C7.46519 19.1579 6.37342 17.9806 6.37342 16.0693V15.903C6.37342 13.9917 7.44937 12.8421 9 12.8421C10.5348 12.8421 11.6424 14.0194 11.6424 15.9307V16.097Z" fill="white" />
        <defs>
          <linearGradient id="paint0_linear_1_13543" x1="10" y1="16" x2="30" y2="16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#064484" />
            <stop offset="1" stopColor="#0F65B5" />
          </linearGradient>
          <linearGradient id="paint1_linear_1_13543" x1="8" y1="26.7692" x2="32" y2="26.7692" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1B366F" />
            <stop offset="1" stopColor="#2657B0" />
          </linearGradient>
          <linearGradient id="paint2_linear_1_13543" x1="32" y1="23" x2="8" y2="23" gradientUnits="userSpaceOnUse">
            <stop stopColor="#44DCFD" />
            <stop offset="0.453125" stopColor="#259ED0" />
          </linearGradient>
          <linearGradient id="paint3_linear_1_13543" x1="8" y1="23" x2="32" y2="23" gradientUnits="userSpaceOnUse">
            <stop stopColor="#259ED0" />
            <stop offset="1" stopColor="#44DCFD" />
          </linearGradient>
          <linearGradient id="paint4_linear_1_13543" x1="0" y1="16" x2="18" y2="16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#064484" />
            <stop offset="1" stopColor="#0F65B5" />
          </linearGradient>
        </defs>
      </svg>

    );
    default:
      return null;
  }
};

export default SvgIcon;
