import React from 'react';

const styles = {
  footer: {
    padding: '2rem 1rem',
    backgroundColor: '#000',
    color: '#fff',
    textAlign: 'center',
  },
  links: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    fontWeight: '500',
    fontSize: '0.875rem',
  },
};

function Footer() {
  return (
    <footer style={styles.footer}>
      <p>&copy; 2024 xTemplater. All rights reserved.</p>
      <div style={styles.links}>
        <a href="#privacy" style={styles.link}>
          Privacy Policy
        </a>
        <a href="#terms" style={styles.link}>
          Terms of Use
        </a>
        <a href="#contact" style={styles.link}>
          Contact
        </a>
      </div>
    </footer>
  );
}

export default Footer;
