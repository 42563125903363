import React, { useEffect, useState } from "react";
import "./Features.css";

const styles = {
  section: {
    padding: "4rem 2rem",
    backgroundColor: "rgb(250, 250, 251)", // Light gray background
    minHeight: "85vh",
    opacity: 0, // Initially hidden
    transform: "translateY(50px)", // Slide up effect
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
  },
  visibleSection: {
    opacity: 1,
    transform: "translateY(0)", // Final position when visible
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  header: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#000", // Black title for better contrast
  },
  description: {
    fontSize: "1.25rem",
    color: "#333",
    marginInline: "auto",
    width: "60%", // Darker gray for improved readability
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "1.5rem",
    marginTop: "3.2rem",
  },
  card: {
    padding: "1.5rem",
    border: "1px solid #ddd",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: "#fff", // White background for cards
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    opacity: 0, // Initially hidden
    transform: "translateX(-50px)", // Slide in from the left
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
  },
  visibleCard: (index) => ({
    opacity: 1,
    transform: "translateX(0)", // Final position when visible
    transitionDelay: `${index * 0.2}s`, // Stagger animation by index
  }),
  cardTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
    color: "#000", // Black for card titles
  },
  cardDescription: {
    fontSize: "1rem",
    color: "#555", // Medium gray for descriptions
  },
};

function Features() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const section = document.getElementById("features-detail");
    const isMobileView = window.innerWidth <= 768;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Animacja/akcja aktywowana
        }
      },
      {
        threshold: isMobileView ? 0.1 : 0.2, // Ustaw inny próg dla mobilnych (10%) i desktopowych (20%)
      }
    );
  
    if (section) {
      observer.observe(section);
    }
  
    // Sprzątanie po unmount
    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  const features = [
    {
      title: "Advanced Multilingual Support",
      description:
        "Create and manage multilingual documents effortlessly, with full localization and language variation support for global reach.",
    },
    {
      title: "AI-Powered Conversational Interface",
      description:
        "Simplify document creation with an intelligent chatbot that guides you through the process, making even complex tasks easy.",
    },
    {
      title: "Intelligent Document Classification and Tagging",
      description:
        "Organize your documents better with AI-generated descriptions and tags. Enable the chatbot to decide which document to generate based on user input.",
    },
    {
      title: "Universal Across Multiple Platforms",
      description:
        "Access xTemplater on platforms like Microsoft Word, Figma, and Adobe Reader, ensuring consistent functionality across your tools.",
    },
    {
      title: "Developer-Friendly API",
      description:
        "Integrate xTemplater with your own applications and systems using our flexible API to enhance automation and connectivity.",
    },
    {
      title: "Document Chaining",
      description:
        "Generate multiple interconnected documents in one step, saving time and ensuring consistency across related files.",
    },
  ];

  return (
<section
  id="features-detail"
  className={isVisible ? "visible" : ""}
>
  <div className="container">
    <div className="header-features">
      <h2 className="title">Why Choose xTemplater?</h2>
      <p className="description">
        xTemplater simplifies complex document creation, making it easy for teams around the world
        to produce, manage, and share templates effortlessly.
      </p>
    </div>
    <div className="grid">
      {features.map((feature, index) => (
        <div
          key={index}
          className={`card ${isVisible ? "visible" : ""}`}
          style={{ transitionDelay: `${index * 0.2}s` }}
        >
          <h3 className="card-title">{feature.title}</h3>
          <p className="card-description">{feature.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>

  );
}

export default Features;
