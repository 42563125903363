import React, { useEffect, useState } from "react";

const styles = {
  section: {
    padding: "4rem 2rem",
    backgroundColor: "#fafafb",
    opacity: 0, // Start hidden
    transform: "translateY(20px)", // Slide up animation
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
  },
  visibleSection: {
    opacity: 1,
    transform: "translateY(0)",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  header: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    opacity: 0, // Hidden initially
    transform: "translateY(20px)", // Slide up animation
    transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
  },
  visibleTitle: {
    opacity: 1,
    transform: "translateY(0)",
  },
  description: {
    fontSize: "1.25rem",
    color: "#555",
    opacity: 0, // Hidden initially
    transform: "translateY(20px)", // Slide up animation
    transition: "opacity 0.6s ease-out 0.2s, transform 0.6s ease-out 0.2s", // Delay for smooth effect
  },
  visibleDescription: {
    opacity: 1,
    transform: "translateY(0)",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "1.5rem",
    marginTop: "2rem",
  },
  card: {
    padding: "1.5rem",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    opacity: 0, // Hidden initially
    transform: "translateY(20px)", // Slide up animation
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
  },
  visibleCard: {
    opacity: 1,
    transform: "translateY(0)",
  },
  cardTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  number: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#000",
    margin: "0.5rem 0",
  },
  cardDescription: {
    fontSize: "1rem",
    color: "#555",
  },
};

function BusinessFeatures() {
  const [isVisible, setIsVisible] = useState(false);
  const [animatedNumbers, setAnimatedNumbers] = useState({
    documents: 0,
    storage: 0,
    templates: 0,
  });

  useEffect(() => {
    const section = document.getElementById("business-features");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const duration = 2000; // Animation duration in ms
      const increment = 10; // Interval for updates in ms

      const animateNumber = (key, target) => {
        const steps = duration / increment;
        const stepValue = target / steps;

        let current = 0;
        const interval = setInterval(() => {
          current += stepValue;
          if (current >= target) {
            current = target; // Stop at the target value
            clearInterval(interval);
          }
          setAnimatedNumbers((prev) => ({ ...prev, [key]: Math.floor(current) }));
        }, increment);
      };

      animateNumber("documents", 25000);
      animateNumber("storage", 1500);
      animateNumber("templates", 120);
    }
  }, [isVisible]);

  return (
    <section
      style={{
        ...styles.section,
        ...(isVisible ? styles.visibleSection : {}),
      }}
      id="business-features"
    >
      <div style={styles.container}>
        <div style={styles.header}>
          <h2
            style={{
              ...styles.title,
              ...(isVisible ? styles.visibleTitle : {}),
            }}
          >
            Essential xTemplater Features for Your Business
          </h2>
          <p
            style={{
              ...styles.description,
              ...(isVisible ? styles.visibleDescription : {}),
            }}
          >
            Explore features designed to transform your document creation and
            management process.
          </p>
        </div>
        <div style={styles.grid}>
          <div
            style={{
              ...styles.card,
              ...(isVisible ? styles.visibleCard : {}),
            }}
          >
            <h3 style={styles.cardTitle}>Documents Generated</h3>
            <div style={styles.number}>{animatedNumbers.documents.toLocaleString()}</div>
            <p style={styles.cardDescription}>
              Fill out your documents through a natural conversation with AI—no need for traditional forms.
            </p>
          </div>
          <div
            style={{
              ...styles.card,
              ...(isVisible ? styles.visibleCard : {}),
            }}
          >
            <h3 style={styles.cardTitle}>Portable Storage</h3>
            <div style={styles.number}>{animatedNumbers.storage.toLocaleString()}</div>
            <p style={styles.cardDescription}>
              Store all form data directly in the Word file, enabling easy sharing and collaboration.
            </p>
          </div>
          <div
            style={{
              ...styles.card,
              ...(isVisible ? styles.visibleCard : {}),
            }}
          >
            <h3 style={styles.cardTitle}>Templates Available</h3>
            <div style={styles.number}>{animatedNumbers.templates.toLocaleString()}</div>
            <p style={styles.cardDescription}>
              Access a library of templates tailored to meet your needs, from reports to contracts.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BusinessFeatures;
