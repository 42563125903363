import React, { useEffect, useState } from "react";
import "./IndustrySolutions.css";

const data = [
  {
    title: "For Government Agencies",
    description:
      "Streamline the creation of official documents with multilingual support and ensure compliance with local regulations. Simplify complex paperwork processes for both staff and citizens.",
    image: "/images/goverment.jpg", // Upewnij się, że ścieżka jest poprawna
  },
  {
    title: "For Legal Firms",
    description:
      "Automate the generation of complex legal documents with our AI-powered chatbot. Reduce errors and save time on drafting contracts, agreements, and more.",
    image: "/images/lawFirm.jpg",
  },
  {
    title: "For Corporations",
    description:
      "Enhance efficiency by automating internal documentation, reports, and client communications. Maintain consistency across all documents and departments.",
    image: "/images/corporations.jpg",
  },
  {
    title: "For Developers",
    description:
      "Utilize our open API to integrate xTemplater functionalities into your own applications. Customize and extend capabilities to suit your project's needs.",
    image: "/images/developers.jpg",
  },
];

function IndustrySolutions() {
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [visibleBlocks, setVisibleBlocks] = useState([]);

  useEffect(() => {
    const section = document.getElementById("industry-solutions");
    const blockElements = document.querySelectorAll(".industry-solutions__block");

    if (!section) {
      console.warn("Section with id 'industry-solutions' not found");
      return;
    }

    // Observer dla widoczności sekcji
    const sectionObserver = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsSectionVisible(true);
            observerInstance.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1, // Mniejszy próg, aby animacja uruchomiła się wcześniej
        rootMargin: "0px",
      }
    );

    sectionObserver.observe(section);

    // Observer dla poszczególnych bloków
    const blockObserver = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const blockIndex = Array.from(blockElements).indexOf(entry.target);
            if (blockIndex !== -1 && !visibleBlocks.includes(blockIndex)) {
              setVisibleBlocks((prev) => [...prev, blockIndex]);
              observerInstance.unobserve(entry.target);
            }
          }
        });
      },
      {
        threshold: 0.3, // Próg dla bloków
        rootMargin: "0px",
      }
    );

    blockElements.forEach((block) => blockObserver.observe(block));

    return () => {
      sectionObserver.disconnect();
      blockObserver.disconnect();
    };
  }, []); // Usunięty 'visibleBlocks' z dependency array

  return (
    <section
      id="industry-solutions"
      className={`industry-solutions ${
        isSectionVisible ? "industry-solutions--visible" : ""
      }`}
    >
      <div
        className={`industry-solutions__header ${
          isSectionVisible ? "industry-solutions__header--visible" : ""
        }`}
      >
        <h2 className="industry-solutions__title">
          Tailored Solutions for Every Industry
        </h2>
        <p className="industry-solutions__subtitle">
          Discover how xTemplater can transform workflows across different sectors.
        </p>
      </div>
      <div className="industry-solutions__container">
        {data.map((item, index) => (
          <div
            key={item.title}
            className={`industry-solutions__block ${
              index % 2 === 1 ? "industry-solutions__block--reverse" : ""
            } ${
              visibleBlocks.includes(index) ? "industry-solutions__block--visible" : ""
            }`}
            style={{ transitionDelay: `${index * 0.2}s` }}
          >
            <img
              src={item.image}
              alt={item.title}
              className="industry-solutions__image"
            />
            <div className="industry-solutions__text">
              <h3 className="industry-solutions__block-title">{item.title}</h3>
              <p className="industry-solutions__block-description">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default IndustrySolutions;
